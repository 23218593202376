import { FC, PropsWithChildren, useEffect, useLayoutEffect, useRef, useState } from 'react';

export const LazyLoad: FC<PropsWithChildren> = ({ children }) => {
  const [isInited, setIsInited] = useState(false);
  const intersectionRef = useRef<HTMLDivElement>();

  useLayoutEffect(() => {
    if (isInited || !intersectionRef.current) {
      return;
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        entry?.isIntersecting && setIsInited(true);
      },
      {
        rootMargin: '0px 0px 50px 0px',
        threshold: 1
      }
    );

    observer.observe(intersectionRef.current);

    return () => {
      intersectionRef.current && observer.unobserve(intersectionRef.current);
    };
  }, [intersectionRef, isInited]);
  return (
    <>
      <div ref={intersectionRef}></div>
      {isInited ? children : null}
    </>
  );
};
