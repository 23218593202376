const wait = (ms: number) => new Promise((res) => setTimeout(res, ms));

export const callWithRetry = async <T extends () => Promise<any> | any>(
  fn: T,
  maxDepth: number,
  depth = 0
): Promise<Awaited<ReturnType<T>>> => {
  try {
    return await fn();
  } catch (e) {
    if (depth > maxDepth) {
      throw e;
    }
    await wait(2 ** depth * 500);

    return await callWithRetry(fn, maxDepth, depth + 1);
  }
};
