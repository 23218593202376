import { AxiosResponse } from 'axios';

export const setTitle = (response: AxiosResponse<any, any>) => {
  if (response.data) {
    if (response.data.pageTitle) {
      // set page title here
    }
    if (response.data.browserTitle) {
      const titleELem = document.querySelector('title[itemprop]');
      const titleParts = titleELem?.innerHTML?.split(' | ');
      if (titleParts?.length > 0) {
        titleParts[0] = response.data.browserTitle;
        titleELem.innerHTML = titleParts.join(' | ');
      }
    }
  }

  return response;
};
