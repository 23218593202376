class StringExtensions {
  isEmptyOrSpaces = (str: string) => {
    return !str || !str.trim();
  };

  removeHtmlTags = (str: string) => {
    if (str === null || str === '') return '';
    else str = str.toString();

    return str.replace(/(<([^>]+)>)/gi, '');
  };

  truncate = (str, n) => {
    return str.length > n ? `${str.slice(0, n - 1)}...` : str;
  };
}

export const stringExtensions = new StringExtensions();
