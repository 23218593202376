class Typeguard {
  isOfType = <TFirst extends object>(
    targetObject: TFirst | any,
    validationKeys: (keyof TFirst)[]
  ): targetObject is TFirst => {
    return validationKeys.every((key) => key in targetObject);
  };
}

export const typeGuard = new Typeguard();
