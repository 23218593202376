import { useCallback, useRef } from 'react';

type MessageBusHandler<T> = (data: T) => Promise<any> | any;

const useMessageBus = <T,>(eventName: string) => {
  const eventBusName = useRef<string>(`${eventName}-message-bus`);
  const handlers = useRef<EventListener[]>([]);

  const subscribe = useCallback((handler: MessageBusHandler<T>) => {
    const eventHandler = (event: CustomEvent<T>) => {
      handler(event.detail);
    };

    handlers.current.push(eventHandler);

    window.addEventListener(eventBusName.current, eventHandler);
  }, []);

  const unsubscribe = useCallback(() => {
    handlers.current.forEach((handler) =>
      window.removeEventListener(eventBusName.current, handler)
    );

    handlers.current = [];
  }, []);

  const notify = useCallback((data: T) => {
    const event = new CustomEvent(eventBusName.current, {
      detail: data,
      bubbles: true,
      cancelable: true
    });

    window.dispatchEvent(event);
  }, []);

  return { subscribe, unsubscribe, notify };
};

export { useMessageBus };
