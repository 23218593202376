import React, { ComponentType, FC, lazy, Suspense } from 'react';
import { appHelper } from './helpers/appHelper';
import { createRoot, hydrateRoot } from 'react-dom/client';
import GlobalLoader from './components/GlobalLoader/GlobalLoader';
import { ReactWidget } from './types/react-module';
import { LazyLoad } from './shared/LazyLoad';

const ScheduleByPortV2 = lazy(
  () =>
    import(
      /* webpackChunkName: "scheduleByPortV2" */ './components/NewUI/ScheduleByPortV2/ScheduleByPortV2'
    )
);
const AppLoadError = lazy(
  () => import(/* webpackChunkName: "appLoadError" */ './components/AppLoadError')
);
const EContainerLazy = lazy(
  () => import(/* webpackChunkName: "econtainer" */ './components/Econtainer/Econtainer')
);
const TareWeightLazy = lazy(
  () => import(/* webpackChunkName: "tareWeight" */ './components/TareWeight/TareWeight')
);
const StickyBarLazy = lazy(
  () => import(/* webpackChunkName: "stikyBar" */ './components/StickyBar/StickyBar')
);
const NavBarLazy = lazy(
  () =>
    import(/* webpackChunkName: "navBarVersioned" */ './components/NewUI/NavbarV2/NavbarVersioned')
);
const Trades = lazy(() => import(/* webpackChunkName: "trades" */ './components/Trades/Trades'));
const Careers = lazy(
  () => import(/* webpackChunkName: "careers" */ './components/Careers/Careers')
);
const SearchHistoryBar = lazy(
  () =>
    import(
      /* webpackChunkName: "searchHistoryBar" */ './components/SearchHistoryP2P/SearchHistoryBar'
    )
);
const StorageCertificate = lazy(
  () =>
    import(
      /* webpackChunkName: "storageCertificate" */ './components/StorageCertificate/StorageCertificate'
    )
);
const ExpectedVessels = lazy(
  () =>
    import(/* webpackChunkName: "expectedVessels" */ './components/ExpectedVessels/ExpectedVessels')
);
const StatusOfVessel = lazy(
  () =>
    import(/* webpackChunkName: "statusOfVessel" */ './components/StatusOfVessel/StatusOfVessel')
);
const EcoDataCalculator = lazy(
  () =>
    import(
      /* webpackChunkName: "ecoDataCalculator" */ './components/EcoDataCalculator/EcoDataCalculator'
    )
);
const EtaForVessels = lazy(
  () => import(/* webpackChunkName: "etaForVessels" */ './components/EtaForVessels/EtaForVessels')
);
const ScheduleByVessel = lazy(
  () =>
    import(
      /* webpackChunkName: "scheduleByVessel" */ './components/ScheduleByVessel/ScheduleByVessel'
    )
);
const ScheduleByLineResult = lazy(
  () =>
    import(
      /* webpackChunkName: "scheduleByLineResult" */ './components/ScheduleByLine/Result/Result'
    )
);
const ScheduleByVesselV2 = lazy(
  () =>
    import(
      /* webpackChunkName: "scheduleByVesselV2" */ './components/ScheduleByVesselV2/ScheduleByVessel'
    )
);
const ScheduleByLineSearch = lazy(
  () =>
    import(
      /* webpackChunkName: "scheduleByLineSearch" */ './components/ScheduleByLine/Search/Search'
    )
);
const ZimTrades = lazy(
  () => import(/* webpackChunkName: "zimTrades" */ './components/Sidebar/ZimTrades/ZimTrades')
);
const LineDetails = lazy(
  () =>
    import(/* webpackChunkName: "lineDetails" */ './components/ScheduleByLine/Details/LineDetails')
);
const UnsubscribeSms = lazy(
  () =>
    import(/* webpackChunkName: "unsubscribeSms" */ './components/Unsubscribe/Sms/UnsubscribeSms')
);
const UnsubscribeChannels = lazy(
  () =>
    import(
      /* webpackChunkName: "unsubscribeChannels" */ './components/Unsubscribe/ChannelsTabs/ChannelsTabs'
    )
);
const SmsChannel = lazy(
  () =>
    import(
      /* webpackChunkName: "smsChannel" */ './components/Unsubscribe/ChannelsTabs/Channels/SmsChannel'
    )
);
const Career = lazy(() => import(/* webpackChunkName: "career" */ './components/Careers/Career'));
const CareerPositionSubject = lazy(
  () =>
    import(
      /* webpackChunkName: "careerPositionSubject" */ './components/CareerPositionSubject/CareerPositionSubject'
    )
);
const BillOfLading = lazy(
  () => import(/* webpackChunkName: "billOfLading" */ './components/BillOfLading/BillOfLading')
);
const SchedulePointToPoint = lazy(
  () =>
    import(
      /* webpackChunkName: "schedulePointToPoint" */ './components/SchedulePointToPoint/SchedulePointToPoint'
    )
);
const SchedulePointToPointV2 = lazy(
  () =>
    import(
      /* webpackChunkName: "schedulePointToPointV2" */ './components/SchedulePointToPointV2/SchedulePointToPoint'
    )
);

const TrackShipment = lazy(
  () => import(/* webpackChunkName: "trackShipment" */ './components/TrackShipment/TrackShipment')
);
const ScheduleByPortSearch = lazy(
  () =>
    import(
      /* webpackChunkName: "scheduleByPortSearch" */ './components/ScheduleByPort/Search/Search'
    )
);
const ScheduleByPortResult = lazy(
  () =>
    import(
      /* webpackChunkName: "scheduleByPortResult" */ './components/ScheduleByPort/Result/Result'
    )
);

const LocalCharges = lazy(
  () => import(/* webpackChunkName: "localCharges" */ './components/LocalCharges/LocalCharges')
);

const UsStatus = lazy(
  () => import(/* webpackChunkName: "usStatus" */ './components/UsStatus/UsStatus')
);
const Demurrage = lazy(
  () => import(/* webpackChunkName: "demurrage" */ './components/Demurrage/Demurrage')
);
const DemurrageCalculator = lazy(
  () =>
    import(
      /* webpackChunkName: "demurrageCalculator" */ './components/DemurrageCalculator/DemurrageCalculator'
    )
);

const NonDsaSubscribe = lazy(
  () =>
    import(/* webpackChunkName: "nonDsaSubscribe" */ './components/NonDsaSubscribe/NonDsaSubscribe')
);
const GlobalBanner = lazy(
  () => import(/* webpackChunkName: "globalBanner" */ './components/Banners/GlobalBanner')
);
const InnerBanner = lazy(
  () => import(/* webpackChunkName: "innerBanner" */ './components/Banners/InnerBanner')
);
const HomepageSlider = lazy(
  () => import(/* webpackChunkName: "homepageSlider" */ './components/Homepage/HomepageSlider')
);
const CargoServicesSliderVersioned = lazy(
  () =>
    import(
      /* webpackChunkName: "cargoServicesSliderVersioned" */ './components/NewUI/CargoServicesSliderV2/CargoServicesSliderVersioned'
    )
);
const ZimSearch = lazy(
  () => import(/* webpackChunkName: "zimSearch" */ './components/ZimSearch/ZimSearch')
);
const GridImageGallery = lazy(
  () =>
    import(
      /* webpackChunkName: "gridImageGallery" */ './components/GridImageGallery/GridImageGallery'
    )
);
const ReusablePromotedSubjects = lazy(
  () =>
    import(
      /* webpackChunkName: "reusablePromotedSubjects" */ './components/ReusablePromotedSubjects/ReusablePromotedSubjects'
    )
);

const UsLandTransportationTariff = lazy(
  () =>
    import(
      /* webpackChunkName: "usLandTransportationTariff" */ './components/UsLandTransportationTariff/UsLandTransportationTariff'
    )
);
const NewsSectionVersioned = lazy(
  () =>
    import(
      /* webpackChunkName: "newsSectionVersioned" */ './components/NewUI/NewsSectionV2/NewsSectionVersioned'
    )
);

const TariffCalculator = lazy(
  () =>
    import(
      /* webpackChunkName: "tariffCalculator" */ './components/TariffCalculator/TariffCalculator'
    )
);

const ContactUs = lazy(
  () => import(/* webpackChunkName: "contactUs" */ './components/ContactUs/ContactUs')
);
const Faq = lazy(() => import(/* webpackChunkName: "faq" */ './components/FAQ/Faq'));

const SearchBar = lazy(
  () => import(/* webpackChunkName: "searchBar" */ './components/Homepage/SearchBar')
);
const EmailSignup = lazy(
  () =>
    import(/* webpackChunkName: "emailSignup" */ './components/Newsletter/EmailSignup/EmailSignup')
);
const EmailPopup = lazy(
  () => import(/* webpackChunkName: "emailPopup" */ './components/Newsletter/EmailPopup/EmailPopup')
);
const EmailSignupContentPage = lazy(
  () =>
    import(
      /* webpackChunkName: "emailSignupContentPage" */ './components/Newsletter/EmailSignupContentPage/EmailSignupContentPage'
    )
);
const EmailSignupLandingPage = lazy(
  () =>
    import(
      /* webpackChunkName: "emailSignupLandingPage" */ './components/Newsletter/EmailSignupLandingPage/EmailSignupLandingPage'
    )
);
const EmailEditPref = lazy(
  () =>
    import(
      /* webpackChunkName: "emailEditPref" */ './components/Newsletter/EmailEditPref/EmailEditPref'
    )
);
const EmailSignupStep0 = lazy(
  () =>
    import(
      /* webpackChunkName: "emailSignupStep0" */ './components/Newsletter/EmailSignupStep0/EmailSignupStep0'
    )
);
const NewsletterSubscribe = lazy(
  () =>
    import(
      /* webpackChunkName: "newsletterSubscribe" */ './components/Newsletter/Subscribe/NewsletterSubscribe'
    )
);
const NewUXUIBanner = lazy(
  () => import(/* webpackChunkName: "newUXUIBanner" */ './components/NewUXUIBanner/NewUXUIBanner')
);
const TabsGallery = lazy(
  () => import(/* webpackChunkName: "tabsGallery" */ './components/TabsGallery/TabsGallery')
);
const DemurrageDispute = lazy(
  () =>
    import(
      /* webpackChunkName: "demurrageDispute" */ './components/DemurrageDispute/DemurrageDispute'
    )
);
const GlobalNetworkPromotion = lazy(
  () =>
    import(
      /* webpackChunkName: "globalNetworkPromotion" */ './components/NewUI/GlobalNetworkPromotion/GlobalNetworkPromotion'
    )
);
const SimpleRequestAQuote = lazy(
  () =>
    import(
      /* webpackChunkName: "simpleRequestAQuote" */ './components/SimpleRequestAQuote/SimpleRequestAQuote'
    )
);
const HomepageSliderVersioned = lazy(
  () =>
    import(
      /* webpackChunkName: "homepageSliderVersioned" */ './components/NewUI/HomepageSliderV2/HomepageSliderVersioned'
    )
);
const Footer = lazy(() => import(/* webpackChunkName: "footer" */ './components/Footer/Footer'));
const NewsBar = lazy(() => import(/* webpackChunkName: "newsBar" */ './components/News/NewsBar'));
const QuickContact = lazy(
  () =>
    import(/* webpackChunkName: "quickContact" */ './components/NewUI/QuickContact/QuickContact')
);

const EcoDataCalculatorV2 = lazy(
  () =>
    import(
      /* webpackChunkName: "ecoDataCalculatorV2" */ './components/NewUI/EcoDataCalculatorV2/EcoDataCalculatorV2'
    )
);
const EContainerV2 = lazy(
  () =>
    import(/* webpackChunkName: "eContainerV2" */ './components/NewUI/EContainerV2/EContainerV2')
);
const TareWeightInquiryV2 = lazy(
  () =>
    import(
      /* webpackChunkName: "tareWeightInquiryV2" */ './components/NewUI/TareWeightInquiryV2/TareWeightInquiryV2'
    )
);
const BillOfLandingV2 = lazy(
  () =>
    import(
      /* webpackChunkName: "billOfLandingV2" */ './components/NewUI/BillOfLadingV2/CountryRequirementV2'
    )
);
const ContactUsV2 = lazy(
  () => import(/* webpackChunkName: "contactUsV2" */ './components/ContactUsV2/ContactUs')
);
const contactUsForms = lazy(
  () =>
    import(/* webpackChunkName: "contactUsForms" */ './components/ContactUsForms/ContactUsForms')
);
const contactUsCountries = lazy(
  () =>
    import(
      /* webpackChunkName: "contactUsCountries" */ './components/ContactUsCountries/ContactUsCountries'
    )
);
const tableDyanmic = lazy(
  () => import(/* webpackChunkName: "tableDyanmic" */ './components/Table/DynamicTable')
);
const LocalChargesV2 = lazy(
  () =>
    import(
      /* webpackChunkName: "localChargesV2" */ './components/NewUI/LocalChargesV2/LocalChargesV2'
    )
);
const DemurrageVersioned = lazy(
  () =>
    import(
      /* webpackChunkName: "demurrageVersioned" */ './components/NewUI/DemurrageV2/DemurrageVersioned'
    )
);

const TariffCalculatorV2 = lazy(
  () =>
    import(
      /* webpackChunkName: "tariffCalculatorV2" */ './components/NewUI/TariffCalculatorV2/TariffCalculatorV2'
    )
);
const StorageCertificateV2 = lazy(
  () =>
    import(
      /* webpackChunkName: "storageCertificateV2" */ './components/NewUI/StroageCertficiateV2/StorageCertificateV2'
    )
);
const UsLandTransportationTariffV2 = lazy(
  () =>
    import(
      /* webpackChunkName: "usLandTransportationTariffV2" */ './components/NewUI/UsLandTransportationTariffV2/UsLandTransportationTariffV2'
    )
);

const DemurrageCalculatorVersioned = lazy(
  () =>
    import(
      /* webpackChunkName: "demurrageCalculatorVersioned" */ './components/NewUI/DemurrageCalculatorV2/DemurrageCalculatorVersioned'
    )
);

const ToolsOverviewV2 = lazy(
  () =>
    import(
      /* webpackChunkName: "toolsOverviewV2" */ './components/NewUI/ToolsOverviewV2/ToolsOverviewV2'
    )
);
const CareersV2 = lazy(
  () => import(/* webpackChunkName: "careersV2" */ './components/NewUI/CareersV2/CareersV2')
);
const CareerV2 = lazy(
  () => import(/* webpackChunkName: "careerV2" */ './components/NewUI/CareersV2/CareerV2')
);

const HrGallery = lazy(
  () => import(/* webpackChunkName: "hrGallery" */ './components/NewUI/HrGallery/HrGallery')
);
const EContainerCertificateV2 = lazy(
  () =>
    import(
      /* webpackChunkName: "eContainerCertificateV2" */ './components/NewUI/EContainerCertificateV2/EContainerCertificateV2'
    )
);
const UsStatusV2 = lazy(
  () => import(/* webpackChunkName: "usStatusV2" */ './components/NewUI/UsStatusV2/UsStatusV2')
);
const ShareButton = lazy(
  () => import(/* webpackChunkName: "shareButton" */ './shared/ShareButton/ShareButton')
);
const AwardGallery = lazy(
  () =>
    import(/* webpackChunkName: "awardGallery" */ './components/NewUI/AwardGallery/AwardGallery')
);
const TimeLine = lazy(
  () => import(/* webpackChunkName: "timeLine" */ './components/NewUI/TimeLine/TimeLine')
);
const DynamicFormSource = lazy(
  () =>
    import(/* webpackChunkName: "dynamicFormSource" */ './components/NewUI/Forms/DynamicFormSource')
);
const DynamicLightRegistrationForm = lazy(
  () =>
    import(
      /* webpackChunkName: "dynamicLightRegistrationForm" */ './components/NewUI/Forms/LightRegistrationForm/DynamicLightRegistrationForm'
    )
);
const LandingPageForm = lazy(
  () =>
    import(
      /* webpackChunkName: "landingPageForm" */ './components/NewUI/Forms/LandingPageForm/LandingPageForm'
    )
);
const ShareSocialModal = lazy(
  () =>
    import(
      /* webpackChunkName: "shareSocialModal" */ './components/NewUI/ShareSocialModal/ShareSocialModal'
    )
);
const UnsubscribeChannelsV2 = lazy(
  () =>
    import(
      /* webpackChunkName: "unsubscribeChannelsV2" */ './components/NewUI/UnsubscribeV2/ChannelsTabsV2/ChannelsTabsV2'
    )
);
const SmsChannelV2 = lazy(
  () =>
    import(
      /* webpackChunkName: "smsChannelV2" */ './components/NewUI/UnsubscribeV2/ChannelsTabsV2/ChannelsV2/SmsChannelV2'
    )
);

const GlobalNetworkV2 = lazy(
  () =>
    import(
      /* webpackChunkName: "globalNetworkV2" */ './components/NewUI/GlobalNetworkV2/GlobalNetworkV2'
    )
);
const TrackShipmentV2 = lazy(
  () =>
    import(
      /* webpackChunkName: "trackShipmentV2" */ './components/NewUI/TrackShipmentV2/TrackShipmentV2'
    )
);
const LightRegistrationForm = lazy(
  () => import('./components/LightRegistrationForm/LightRegistrationForm')
);
const StatusVesselsV2 = lazy(
  () =>
    import(
      /* webpackChunkName: "statusOfVesselsInPortsV2" */ './components/NewUI/StatusVesselsV2/StatusVesselsInPortsV2'
    )
);
const ScheduleByLineV2 = lazy(
  () =>
    import(
      /* webpackChunkName: "scheduleByLineV2" */ './components/NewUI/ScheduleByLineV2/ScheduleByLineV2'
    )
);
const ExpectedVesselsV2 = lazy(
  () =>
    import(
      /* webpackChunkName: "expectedVesselsV2" */ './components/NewUI/ExpectedVesselsV2/ExpectedVesselsV2'
    )
);
const EtaForVesselsV2 = lazy(
  () =>
    import(
      /* webpackChunkName: "etaForVesselsV2" */ './components/NewUI/EtaForVesselsV2/EtaForVesselsV2'
    )
);
const CustomerUpdatesV2 = lazy(
  () =>
    import(
      /* webpackChunkName: "customerUpdatesV2" */ './components/NewUI/CustomerUpdatesV2/CustomerUpdatesV2'
    )
);
const ReleaseStatus = lazy(
  () =>
    import(/* webpackChunkName: "releaseStatus" */ './components/NewUI/ReleaseStatus/ReleaseStatus')
);
const DemurrageDisputeVersioned = lazy(
  () =>
    import(
      /* webpackChunkName: "demurrageDisputeVersioned" */ './components/NewUI/DemurrageDisputeV2/DemurrageDisputeVersioned'
    )
);

const mainElement = document.getElementById('main');
const isLazyLoaded = mainElement?.getAttribute('data-lazy') === 'true';
const parseTarget = mainElement?.getAttribute('data-module') || '{}';
const pageModule = JSON.parse(parseTarget);

const deployProdApp = (isSuccess: boolean) => {
  if (!isSuccess) {
    const root = createRoot(document.getElementById(pageModule?.find()?.trim()));

    root.render(
      <Suspense
        fallback={
          <div className="services-loader-holder">
            <div className="services-loader" />
          </div>
        }>
        <React.StrictMode>
          <AppLoadError />
        </React.StrictMode>
      </Suspense>
    );

    return;
  }

  const globalLoaderElement = document.getElementById('globalLoader');

  if (globalLoaderElement) {
    hydrateRoot(
      globalLoaderElement,
      <React.StrictMode>
        <GlobalLoader />
      </React.StrictMode>
    );
  }

  const idMap = new Map<
    string,
    {
      Component: ComponentType | FC;
      initialProps?: any;
      supportsLazy?: boolean;
    }
  >();
  idMap.set('scheduleByVesselV2', { Component: ScheduleByVesselV2 });
  idMap.set('globalBanner', { Component: GlobalBanner });
  idMap.set('eContainer', { Component: EContainerLazy });
  idMap.set('tareWeight', { Component: TareWeightLazy });
  idMap.set('stickyBar', { Component: StickyBarLazy });
  idMap.set('navbarReact', { Component: NavBarLazy });
  idMap.set('linesOfTrades', { Component: Trades });
  idMap.set('careers', { Component: Careers });
  idMap.set('career', {
    Component: Career,
    initialProps: { id: appHelper.getLastUrlSegment() }
  });
  idMap.set('careerPositionSubject', { Component: CareerPositionSubject });
  idMap.set('storageCertificate', {
    Component: StorageCertificate,
    initialProps: { key: 'global', isGlobal: true }
  });
  idMap.set('storageCertificateIndia', {
    Component: StorageCertificate,
    initialProps: { key: 'india', isGlobal: false }
  });
  idMap.set('expectedVessels', {
    Component: ExpectedVessels
  });
  idMap.set('statusOfVessel', { Component: StatusOfVessel });
  idMap.set('ecoDataCalculator', {
    Component: EcoDataCalculator
  });
  idMap.set('searchHistoryBar', { Component: SearchHistoryBar });
  idMap.set('etaForVessels', { Component: EtaForVessels });
  idMap.set('scheduleByVessel', { Component: ScheduleByVessel });
  idMap.set('scheduleByLineSearch', { Component: ScheduleByLineSearch });
  idMap.set('scheduleByLineResult', { Component: ScheduleByLineResult });
  idMap.set('sidebarTrades', { Component: ZimTrades });
  idMap.set('lineDetails', { Component: LineDetails });
  idMap.set('unsubscribeSms', { Component: UnsubscribeSms });
  idMap.set('unsubscribeChannels', { Component: UnsubscribeChannels });
  idMap.set('smsChannel', { Component: SmsChannel });
  idMap.set('billOfLading', { Component: BillOfLading });
  idMap.set('schedulePointToPoint', { Component: SchedulePointToPoint });
  idMap.set('schedulePointToPointV2', { Component: SchedulePointToPointV2 });
  idMap.set('trackShipment', { Component: TrackShipment });
  idMap.set('scheduleByPortSearch', { Component: ScheduleByPortSearch });
  idMap.set('scheduleByPortResult', { Component: ScheduleByPortResult });
  idMap.set('localCharges', { Component: LocalCharges });
  idMap.set('usStatus', { Component: UsStatus });
  idMap.set('demurrage', { Component: DemurrageVersioned });
  idMap.set('demurrageCalculator', { Component: DemurrageCalculatorVersioned });
  idMap.set('nonDsaSubscribe', { Component: NonDsaSubscribe });
  idMap.set('usLandTransportationTariff', { Component: UsLandTransportationTariff });
  idMap.set('tariffCalculator', { Component: TariffCalculator });
  idMap.set('innerBanner', { Component: InnerBanner });
  idMap.set('homepageSlider', { Component: HomepageSlider });
  idMap.set('cargoServicesSlider', { Component: CargoServicesSliderVersioned });
  idMap.set('newsSection', { Component: NewsSectionVersioned });
  idMap.set('portSearchBarWidget', { Component: SearchBar });
  idMap.set('zimSearch', { Component: ZimSearch });
  idMap.set('contactUs', { Component: ContactUs });
  idMap.set('gridImageGallery', { Component: GridImageGallery });
  idMap.set('reusablePromotedSubject', { Component: ReusablePromotedSubjects });
  idMap.set('faqWidget', { Component: Faq });
  idMap.set('emailSignup', { Component: EmailSignup });
  idMap.set('emailSignupPopup', { Component: EmailPopup });
  idMap.set('emailSignupContentPage', { Component: EmailSignupContentPage });
  idMap.set('emailSignupLandingPage', { Component: EmailSignupLandingPage });
  idMap.set('emailEditPref', { Component: EmailEditPref });
  idMap.set('emailSignupStep0', { Component: EmailSignupStep0 });
  idMap.set('newsletterSubscribe', { Component: NewsletterSubscribe });
  idMap.set('tabsGallery', { Component: TabsGallery });
  idMap.set('demurrageDispute', { Component: DemurrageDisputeVersioned });
  idMap.set('simpleRequestAQuote', { Component: SimpleRequestAQuote });
  idMap.set('newUXUIBanner', { Component: NewUXUIBanner });
  idMap.set('globalNetworkPromotion', { Component: GlobalNetworkPromotion });
  idMap.set('homepageSlider', { Component: HomepageSliderVersioned });
  idMap.set('footerReact', { Component: Footer });
  idMap.set('newsTickerReact', { Component: NewsBar });
  idMap.set('quickContact', { Component: QuickContact });
  idMap.set('ecoDataCalculatorV2', { Component: EcoDataCalculatorV2 });
  idMap.set('eContainerV2', { Component: EContainerV2 });
  idMap.set('tareWeightInquiryV2', { Component: TareWeightInquiryV2 });
  idMap.set('billOfLandingV2', { Component: BillOfLandingV2 });
  idMap.set('contactUsV2', { Component: ContactUsV2 });
  idMap.set('tableDyanmicWidget', { Component: tableDyanmic });
  idMap.set('contactUsForms', { Component: contactUsForms });
  idMap.set('contactUsCountries', { Component: contactUsCountries });
  idMap.set('localChargesV2', { Component: LocalChargesV2 });
  idMap.set('tariffCalculatorV2', { Component: TariffCalculatorV2 });
  idMap.set('storageCertificateV2', {
    Component: StorageCertificateV2,
    initialProps: { key: 'global', isGlobal: true }
  });
  idMap.set('storageCertificateIndiaV2', {
    Component: StorageCertificateV2,
    initialProps: { key: 'india', isGlobal: false }
  });
  idMap.set('usLandTransportationTariffV2', { Component: UsLandTransportationTariffV2 });
  idMap.set('toolsOverviewV2', { Component: ToolsOverviewV2 });
  idMap.set('careersV2', { Component: CareersV2 });
  idMap.set('careerV2', {
    Component: CareerV2,
    initialProps: { id: appHelper.getLastUrlSegment() }
  });
  idMap.set('hrGalleryWidget', { Component: HrGallery });
  idMap.set('eContainerCertificateV2', { Component: EContainerCertificateV2 });
  idMap.set('usStatusV2', { Component: UsStatusV2 });
  idMap.set('shareButton', { Component: ShareButton });
  idMap.set('awardGallery', { Component: AwardGallery });
  idMap.set('timeLine', { Component: TimeLine });
  idMap.set('dynamicForm', { Component: DynamicFormSource });
  idMap.set('dynamicLightRegistrationForm', { Component: DynamicLightRegistrationForm });
  idMap.set('landingPageForm', { Component: LandingPageForm });
  idMap.set('shareSocialModal', { Component: ShareSocialModal });
  idMap.set('unsubscribeChannelsV2', { Component: UnsubscribeChannelsV2 });
  idMap.set('smsChannelV2', { Component: SmsChannelV2 });
  idMap.set('globalNetworkV2', { Component: GlobalNetworkV2 });
  idMap.set('trackShipmentV2', { Component: TrackShipmentV2 });
  idMap.set('lightRegistrationForm', { Component: LightRegistrationForm });
  idMap.set('scheduleByLineV2', { Component: ScheduleByLineV2 });
  idMap.set('statusOfVesselV2', { Component: StatusVesselsV2 });
  idMap.set('scheduleByPortV2', { Component: ScheduleByPortV2 });
  idMap.set('expectedVesselsV2', { Component: ExpectedVesselsV2 });
  idMap.set('etaForVesselsV2', { Component: EtaForVesselsV2 });
  idMap.set('customerUpdatesV2', { Component: CustomerUpdatesV2 });
  idMap.set('releaseStatus', { Component: ReleaseStatus });

  for (const widget of pageModule) {
    const key = widget.key.trim();
    const targetElement = document.getElementById(key);
    const targetComponent = idMap.get(key);
    if (!targetComponent) {
      continue;
    }
    if (!targetElement) {
      const targetElements = document.querySelectorAll<HTMLElement>('[data-' + key + ']');
      if (targetElements) {
        Array.from(targetElements, (targetElement) => {
          reactModuleCreate(targetComponent, targetElement);
        });
      }
      continue;
    } else {
      reactModuleCreate(targetComponent, targetElement);
    }
  }
};

function reactModuleCreate(targetComponent, targetElement) {
  const Component = targetComponent.Component;
  let initialProps = targetComponent.initialProps ?? {};
  const umbracoProps = targetElement?.dataset?.initial;
  if (umbracoProps) {
    initialProps = { ...JSON.parse(umbracoProps), ...initialProps };
  }
  const root = createRoot(targetElement);
  root.render(
    <Suspense
      fallback={
        <div className="services-loader-holder">
          <div className="services-loader" />
        </div>
      }>
      <React.StrictMode>
        {isLazyLoaded && targetComponent.supportsLazy ? (
          <LazyLoad>
            <Component {...initialProps} />
          </LazyLoad>
        ) : (
          <Component {...initialProps} />
        )}
      </React.StrictMode>
    </Suspense>
  );
}

export { deployProdApp };
