import {
  Translations,
  CultureTranslations,
  TranslationsDictionary
} from '../types/dto/Translations';
import { getData } from './crud';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { typeGuard } from '../helpers/typeguard';
import { ErrorResponse } from '../types/dto/ErrorResponse';
import { ReusableContent, ReusableContentDictionary } from '../types/translations/ReusableContent';
import { env } from '../environments/environment';
import moment from 'moment';
import 'moment/min/locales';

const FALLBACK_REUSABLE_CONTENT = {
  fallback:
    '<h2>Hi there . . .</h2>\n<p>The information you requested is currently unavailable. Please contact your local ZIM office.</p>\n<p><span class="small"> </span></p>'
};

class TranslationService {
  private _culture;
  private _reusableContent: ReusableContent = null;
  private _reusableContentPromise: Promise<ReusableContentDictionary[]>;
  private _language;
  private _dataCultureLanguage: string;

  getCulture = () => {
    if (this._culture) {
      return this._culture;
    } else {
      this._culture = document.getElementById('main')?.getAttribute('data-culture') ?? 'en-US';
      return this._culture;
    }
  };

  getLanguage = () => {
    if (this._language) {
      return this._language;
    } else {
      this._language = document.querySelector('html').getAttribute('lang') ?? 'en';
      return this._language;
    }
  };
  getDataCultureLanguage = () => {
    if (this._dataCultureLanguage) {
      return this._dataCultureLanguage;
    } else {
      this._dataCultureLanguage =
        document.querySelector('html').getAttribute('data-culture-language') ?? 'en-US';
      return this._dataCultureLanguage;
    }
  };
  private reusableContentPromise() {
    if (!this._reusableContentPromise) {
      this._reusableContentPromise = getData<ReusableContentDictionary[]>(
        env.dynamic['REACT_APP_CONNECT_TO_APIM']
          ? `${env.fixed.apimEndpoints.reusableContent}${this._culture}`
          : `${env.fixed.reusableContent}${this._culture}`
      );
    }

    return this._reusableContentPromise;
  }

  getReusableContent = async (): Promise<ReusableContent> => {
    if (!this._culture) {
      this.getCulture();
    }

    if (!this._reusableContent) {
      try {
        const contentUnmapped = await this.reusableContentPromise();
        const result: ReusableContent = { fallback: FALLBACK_REUSABLE_CONTENT.fallback };

        for (const item of contentUnmapped) {
          result[item.key] = item.value;
        }
        this._reusableContent = result;
      } catch {
        this._reusableContent = FALLBACK_REUSABLE_CONTENT;
      }
    }

    return this._reusableContent;
  };
  getCultureTranslations = async (): Promise<CultureTranslations> => {
    if (!this._culture) {
      this.getCulture();
    }

    const sessionTranslations = sessionStorage.getItem(`translations-${this._culture}`);

    const translationsResult: CultureTranslations = {
      lang: this._culture,
      translations: null
    };

    if (sessionTranslations) {
      return {
        ...translationsResult,
        translations: JSON.parse(sessionTranslations)
      };
    }

    const translationsUnmapped = await getData<TranslationsDictionary[] | ErrorResponse>(
      env.dynamic['REACT_APP_CONNECT_TO_APIM']
        ? `${env.fixed.apimEndpoints.translations}${this._culture}`
        : `${env.fixed.translation}${this._culture}`
    );
    const translations = {} as Translations;

    if (typeGuard.isOfType<ErrorResponse>(translations, ['status', 'title', 'type'])) {
      throw new Error("Couldn't load translations");
    }

    (translationsUnmapped as TranslationsDictionary[]).forEach((element) => {
      translations[element.key] = element.value;
    });

    sessionStorage.setItem(`translations-${this._culture}`, JSON.stringify(translations));

    return {
      ...translationsResult,
      translations
    };
  };

  tryInitLocalization = async (): Promise<boolean> => {
    try {
      const cultureTranslations = await this.getCultureTranslations();

      await i18next.use(initReactI18next).init({
        lng: cultureTranslations.lang,
        debug: process.env.NODE_ENV === 'development',
        fallbackLng: 'en-US',
        resources: {
          [cultureTranslations.lang]: {
            translation: cultureTranslations.translations
          }
        }
      });
      moment.locale(this.getCulture());
      return true;
    } catch (error) {
      console.log('Error loading translations: ', error);
      return false;
    }
  };
}

export const translationService = new TranslationService();
