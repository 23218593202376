import './index.scss';
import { mainService } from './services/mainService';
import { translationService } from './services/translationsService';
import { deployProdApp } from './prod';

const deployApp = async () => {
  mainService.init();
  const isSuccess = await translationService.tryInitLocalization();

  if (process.env.NODE_ENV === 'development') {
    const dev = await import('./dev');

    dev.deployDevApp(isSuccess);
    return;
  }

  deployProdApp(isSuccess);
};

deployApp();
