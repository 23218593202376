import { useCallback, useRef } from 'react';
import { useMessageBus } from './useMessageBus';

export const GLOBAL_LOADER_EVENT_BUS = 'global-loader';

export enum GlobalLoaderEventType {
  START = 'LOADER_START',
  FINISH = 'LOADER_FINISH'
}

const useGlobalLoader = () => {
  const alreadyCalled = useRef({
    [GlobalLoaderEventType.START]: false,
    [GlobalLoaderEventType.FINISH]: false
  });
  const { notify } = useMessageBus(GLOBAL_LOADER_EVENT_BUS);

  const handleLoading = useCallback(
    (stage: GlobalLoaderEventType) => {
      if (alreadyCalled.current[stage]) {
        return;
      }
      alreadyCalled.current[stage] = true;
      notify(stage);
    },
    [notify]
  );

  const start = useCallback(() => handleLoading(GlobalLoaderEventType.START), [handleLoading]);

  const finish = useCallback(() => handleLoading(GlobalLoaderEventType.FINISH), [handleLoading]);

  return { start, finish };
};

export { useGlobalLoader };
