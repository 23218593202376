import { Cookies } from 'react-cookie';
import { env } from '../environments/environment';
import { appHelper } from '../helpers/appHelper';
import { callWithRetry } from '../helpers/retryPolicy';

declare global {
  interface Window {
    _paq: any;
  }
}
const PIWIK_MAX_RETRIES = 5;

class MainService {
  private _cookies = new Cookies();

  private _piwik: any = null;

  private _newDesign = false;

  public async getPiwik(): Promise<any> {
    const piwikElement = document.getElementById('piwik-iframe');

    const piwikId = piwikElement?.getAttribute('data-piwik-id');
    const piwikSrc = piwikElement?.getAttribute('data-piwik-src');

    if (!piwikId || !piwikSrc) {
      return null;
    }
    if (!this._piwik) {
      try {
        await callWithRetry(() => {
          if (!window._paq) {
            throw new Error('Piwik paq is not initialized yet');
          }

          this._piwik = window._paq;
        }, PIWIK_MAX_RETRIES);
      } catch (error) {
        console.log('Failed piwik initialization');
      }
    }

    return this._piwik;
  }
  init = () => {
    this._newDesign = document.querySelector('html')?.getAttribute('data-new-ui') === 'True';

    if (!this._cookies.get('uniqueUserId')) {
      this.userIdInit();
    }
    if (this._newDesign && !this._cookies.get('visitedUser')) {
      this.visitedUserInit();
    }
  };

  private get _cookieDomain() {
    const domain = appHelper.getDomainWithoutSubdomain().split('/')[0];

    if (domain === 'localhost') {
      return domain;
    }

    return `.${domain}`;
  }

  private visitedUserInit = () => {
    const userExpDate = new Date();
    userExpDate.setTime(userExpDate.getTime() + 525600 * 60 * 1000);
    const cookieDomain = this._cookieDomain;
    this._cookies.set('visitedUser', 'True', {
      path: '/',
      domain: cookieDomain,
      expires: userExpDate
    });
  };

  private userIdInit = () => {
    const userId = this.generateUUIDUsingMathRandom();
    const userExpDate = new Date();
    userExpDate.setTime(userExpDate.getTime() + 525600 * 60 * 1000);
    const cookieDomain = this._cookieDomain;
    this._cookies.set('uniqueUserId', userId, {
      path: '/',
      domain: cookieDomain,
      expires: userExpDate
    });
  };

  private generateUUIDUsingMathRandom = (): string => {
    let d = new Date().getTime(); //Timestamp
    let d2 = (performance && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = Math.random() * 16; //random number between 0 and 16
      if (d > 0) {
        //Use timestamp until depleted
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {
        //Use microseconds since page-load if supported
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
  };

  getUsersCountry = () => {
    if (process.env.NODE_ENV === 'production') {
      const jUsersCountry = document.querySelector('#main').getAttribute('data-users-country')
        ? JSON.parse(document.querySelector('#main').getAttribute('data-users-country'))
        : null;
      return jUsersCountry;
    } else {
      return null;
    }
  };

  isNewDesign = () => {
    return this._newDesign;
  };
}

export const mainService = new MainService();
