import { useCallback, useEffect, useRef, useState } from 'react';
import { Loader } from 'react-bootstrap-typeahead';
import { GlobalLoaderEventType } from '../../hooks/useGlobalLoader';
import { useMessageBus } from '../../hooks/useMessageBus';

const GlobalLoader = () => {
  const [isLoading, setIsLoading] = useState(true);
  const numberOfLoads = useRef<number>(0);

  const handlerStartLoading = useCallback((event: GlobalLoaderEventType) => {
    switch (event) {
      case GlobalLoaderEventType.START: {
        numberOfLoads.current++;

        setIsLoading((prevState) => (prevState ? prevState : true));
        return;
      }

      case GlobalLoaderEventType.FINISH: {
        numberOfLoads.current--;
        if (numberOfLoads.current <= 0) {
          numberOfLoads.current = 0;

          setIsLoading(false);
          window.dispatchEvent(new CustomEvent('global-loader-closed'));
          return;
        }
        return;
      }

      default: {
        return;
      }
    }
  }, []);

  const { subscribe, unsubscribe } = useMessageBus('global-loader');

  useEffect(() => {
    subscribe(handlerStartLoading);

    return unsubscribe;
  }, [subscribe, unsubscribe, handlerStartLoading]);

  if (!isLoading) {
    return <></>;
  }

  return (
    <div className="global-loader">
      <Loader></Loader>
    </div>
  );
};

export default GlobalLoader;
